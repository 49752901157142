// import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import FilterSection from "../components/FilterSection";
import KnowledgeCenterCard from "../components/knowledgeCenterCard";
import CTA from "../assets/images/cta.webp";

function KnowledgeCenter() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  return (
    <main className="design_ui_ux">
      <div className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
        <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
          <div className="hero-content mx-auto text-center w-[85%] md:w-[92%] xl:w-[75%]">
            <h1 className="mb-4 md:my-4 text-xl py-4  md:text-[2.25rem] font-bold leading-snug text-white sm:text-xl sm:leading-snug  md:leading-snug">
              Welcome to SmewAcademy Knowledge Center
            </h1>
            <p className="text-white mb-6">
              Our Knowledge Center is your gateway to a treasure trove of IT
              insights, tutorials, and resources. Dive into a world of
              programming languages, network security, cloud computing, and
              more. Stay updated on the latest trends and emerging technologies
              that drive the IT industry forward.
            </p>
            <div className=" flex justify-center md:px-4 pb-4 py-8 mx-auto mb-8 font-normal text-sm text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
              <FilterSection handleFilter={handleSearch} />
            </div>
          </div>
        </div>
      </div>

      <section className="body-font bg-gray-100">
        <div className="container py-10 items-center max-w-7xl mx-auto px-6 lg:px-10 md:px-8 xl:px-0">
          <div className="flex flex-col text-center w-full mb-10">
            <h6 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-start">
              Top Articles
            </h6>
          </div>
          <KnowledgeCenterCard searchQuery={searchQuery} />
        </div>
      </section>
      <section className="bg-[#E4D6FF]">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to Get Started?
                </p>
                <h2 className="text-[1.5rem] font-light text-black pb-3">
                  Join us now and explore our platform to enhance your
                  knowledge.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#E75828] border-2 border-[#e75828] hover:bg-orange-700 mt-6 mb-2  text-white py-2 text-base px-10 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Join Us
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA}  className="rounded-xl" alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default KnowledgeCenter;
