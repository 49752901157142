import React from "react";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <footer className="footer bg-black xl:!h-[350px] lg:!h[465px] xl:pb-0 lg:pb-8 pt-5 pb-4">
      <div className="max-w-7xl mx-auto mt-4">
        <div className="grid grid-cols-4 gap-3 xl:!gap-3 md:!gap-1 md:grid-cols-9">
          <div className="!col-span-4 pr-6 xl:!pl-0 lg:!pl-10 md:!pl-10 md:!pr-0 pl-6 md:!col-span-3 lg:!col-span-3">
            <div className="flex flex-wrap items-center justify-start">
              <h1 className="text-white  mb-4 text-left items-center text-xl" >Acadamy</h1>
            </div>
            <p className="text-white opacity-70 mb-4 text-left items-center xl:!w-[400px] lg:!w-[300px] md:!w-[200px]">
            With a mission to empower learners, we provide hands-on experience through real projects and help you earn valuable certifications. Join us and take your skills to the next level.
            </p>

            {/* <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
          <div id="footer-links" className="row">
            <div className="col-lg-4">
              <h6 className="mb-2">
                <a
                  href="#useful-links"
                  className="d-block text-black dropdown-toggle d-lg-none py-2 text-decoration-none"
                  data-bs-toggle="collapse"
                >
                  Useful Links
                </a>
              </h6>
              <div
                id="useful-links"
                className="collapse d-lg-block"
                data-bs-parent="#footer-links"
              >
                <ul className="nav flex-column pb-lg-1 mb-lg-3">
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/services"
                      className="nav-link text-black  d-inline-block px-0 pt-1 pb-2"
                    >
                      Services
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/case-studies"
                      className="nav-link text-black  d-inline-block px-0 pt-1 pb-2"
                    >
                      Case Studies
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/about"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/careers"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      Careers
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/contact"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/investors"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      Investors
                    </NavLink>
                  </li>
                </ul>
                <ul className="nav flex-column mb-2 mb-lg-0">
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/terms"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      Terms &amp; Conditions
                    </NavLink>
                  </li>
                  <li className="nav-item pb-0">
                    <NavLink
                      to="/policy"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-3">
              <h6 className="mb-2">
                <a
                  href="#social-links"
                  className="d-block text-black dropdown-toggle d-lg-none py-2 text-decoration-none"
                  data-bs-toggle="collapse"
                >
                  Socials
                </a>
              </h6>
              <div
                id="social-links"
                className="collapse d-lg-block"
                data-bs-parent="#footer-links"
              >
                <ul className="nav flex-column mb-2 mb-lg-0">
                  <li className="nav-item pb-0">
                    <a
                      href="https://www.facebook.com/techsmew"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      <img
                        className="p-2"
                        src="/assets/images/icons/fb.svg"
                        width="35"
                        alt="techsmew"
                      />
                      Facebook
                    </a>
                  </li>
                  <li className="nav-item pb-0">
                    <a
                      href="https://www.linkedin.com/company/techsmew"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      <img
                        className="p-2"
                        src="/assets/images/icons/in.svg"
                        width="35"
                        alt="techsmew"
                      />
                      LinkedIn
                    </a>
                  </li>
                  <li className="nav-item pb-0">
                    <a
                      href="https://twitter.com/techsmew"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      <img
                        className="p-2"
                        src="/assets/images/icons/twitter.svg"
                        width="35"
                        alt="techsmew"
                      />
                      Twitter
                    </a>
                  </li>
                  <li className="nav-item pb-0">
                    <a
                      href="https://www.instagram.com/offcialtechsmew/"
                      className="nav-link text-black d-inline-block px-0 pt-1 pb-2"
                    >
                      <img
                        className="p-2"
                        src="/assets/images/icons/instagram.svg"
                        width="35"
                        alt="techsmew"
                      />
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
              <h6 className="mb-2 text-black">Contact Us</h6>
              <a
                href="mailto:support@techsmew.com"
                className="text-black text-decoration-none  fw-medium"
              >
                support@techsmew.com
              </a>
            </div>
          </div>
        </div> */}
          </div>
          <div className="text-white opacity-70 gap-3 mt-8 xl:!pl-10 lg:!pl-0 col-span-2 md:!col-span-1 lg:!col-span-2 md:!pl-6 pl-6">
            {" "}
            <ul className="flex-col pb-3">
              <li className="pb-0 xl:pb-2">
                <NavLink to="/" className=" text-white px-0 pt-1 pb-2">
                Techsmew
                </NavLink>
              </li>
              {/* <li className="pb-0 xl:pb-2">
                <NavLink to="/services" className=" text-white px-0 pt-1 pb-2">
                  Services
                </NavLink>
              </li> */}
              <li className="pb-0 xl:pb-2">
                <NavLink
                  to="/case-studies"
                  className=" text-white px-0 pt-1 pb-2"
                >
                  About
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink to="/about-us" className=" text-white px-0 pt-1 pb-2">
                Syllabus
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink
                  to="/careers"
                  className=" text-white d-inline-block px-0 pt-1 pb-2"
                >
                 Case Studies
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink to="/contact" className=" text-white px-0 pt-1 pb-2">
                Reviews
                </NavLink>
              </li>
            
            </ul>
          </div>
          <div className="text-white opacity-70 col-span-2 gap-3 mt-6 xl:!pl-10 lg:!pl-0 md:!pl-6">
            <ul className="flex-col mb-2">
            <li className="pb-0 xl:pb-2 md:pt-2 lg:pt-0">
                <NavLink to="/" className=" text-white px-0 pt-1 pl-4">
                Follow Us
                </NavLink>
              </li>
              <li className="flex pb-0 items-center">
                <a
                  href="https://www.facebook.com/techsmew"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/fb.svg"
                    width="35"
                    alt="techsmew"
                  />
                  Facebook
                </a>
              </li>
              <li className="pb-0">
                <a
                  href="https://www.linkedin.com/company/techsmew"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/in.svg"
                    width="35"
                    alt="techsmew"
                  />
                  LinkedIn
                </a>
              </li>
              <li className="pb-0">
                <a
                  href="https://twitter.com/techsmew"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/twitter.svg"
                    width="35"
                    alt="techsmew"
                  />
                  Twitter
                </a>
              </li>
              <li className="pb-0">
                <a
                  href="https://www.instagram.com/offcialtechsmew/"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/instagram.svg"
                    width="35"
                    alt="techsmew"
                  />
                  Instagram
                </a>
              </li>
            </ul>
          </div>

          <div className="text-white opacity-70 col-span-2 mt-8 gap-3 xl:!pl-10 lg:!pl-0 pr-10 pl-6">
            {" "}
            <h6 className="mb-2 text-white">Contact Us</h6>
            <a
              href="mailto:support@techsmew.com"
              className="text-white text-decoration-none fw-medium"
            >
              support@techsmew.com
            </a>
          </div>
        </div>
        <p className=" text-white text-start text-md-start pb-2 mt-8 mb-0 xl:!pl-0 lg:!pl-10 md:!pl-10 pl-6">
          <span className="text-light opacity-50">
            © All rights reserved. Techsmew Innovations Pvt Ltd{" "}
          </span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
