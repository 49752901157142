import React from "react";
import { images } from "./CourseImages";
import { ClockIcon, CurrencyRupeeIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

const CourseCard = ({ course }) => {
  return (
    <NavLink
      to={"/course-details/" + course.slug}
      className="flex-shrink-0 w-full mb-4"
    >
      <div className="bg-white shadow-lg rounded-lg h-full">
        <img
          src={images[course.image]}
          alt={course.title}
          className="object-cover"
        />
        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">{course.title}</h3>
          <p className="text-gray-700 mb-4 line-clamp-3">
            {course.description}
          </p>
          <div className="flex justify-between">
            {course.learn_mode && (
              <span className="bg-purple-100 p-2 px-4 rounded-full flex items-center">
                <CurrencyRupeeIcon className="w-5 mr-2" />
                {course.price}
              </span>
            )}
            {course.duration && (
              <span className="bg-gray-100 p-2 px-4 rounded-full flex items-center">
                <ClockIcon className="w-5 mr-2" />
                {course.duration}
              </span>
            )}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default CourseCard;
