import React, { useState } from 'react';

const FilterSection = ({ handleFilter }) => {
  const [filter, setFilter] = useState('');

  const handleChange = (e) => {
    setFilter(e.target.value);
    handleFilter(e.target.value);
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg mb-4 w-full"> 
      <input
        type="text"
        value={filter}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded-lg  focus:outline-0 text-black"
        placeholder="Search Here..."
      />
    </div>
  );
};

export default FilterSection;
