import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import coursesData from '../data/courses.json';
import Svg from '../components/svg';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { HomeIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { images } from '../components/CourseImages';

const CourseDetails = () => {

  const { courseSlug } = useParams();
  const course = coursesData.find(course => course.slug === courseSlug);
  const [expandedTopic, setExpandedTopic] = useState(null);

  const handleTopicToggle = (index) => {
    if (expandedTopic === index) {
      setExpandedTopic(null);
    } else {
      setExpandedTopic(index);
    }
  };
  if (!course) {
    return <div className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
      <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
        {/* Hero */}
        <div className="hero-content mx-auto text-center w-[85%] md:w-[92%] xl:w-[75%]">
          <h1 className="mb-4 md:my-6 text-xl py- 48 md:text-2xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
            Course Not Found
          </h1>
          <div className=" flex justify-center md:px-4 pb-4 py-10 mx-auto mb-10 font-normal text-sm text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
            <Link
              to="/courses"
              className="flex w-fit bg-orange-500 hover:bg-orange-700  text-white font-normal text-base py-2 px-6 rounded-full"
            >
              <HomeIcon className=" w-6 h-6 text-white mr-2" />Go to Courses </Link>
          </div>
        </div>
      </div>
    </div>
  }


  return (
    <>
      <Helmet>
        <title>{course.title} | SmewAcademy </title>
        <meta name="description" content="Read our case study on migrating to AWS for an IT company. Experience the benefits of cloud migration." />
        <meta name="keywords" content="AWS migration, cloud migration, IT company" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
        <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
          {/* Hero */}
          <div className="hero-content mx-auto text-center w-[85%] md:w-[92%] xl:w-[75%]">
            <h1 className="mb-4 md:my-6 text-xl md:text-2xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
              {course.title}
            </h1>
            <p className=" md:px-4 pb-4 mx-auto mb-10 font-normal text-sm text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
              {course.description}
            </p>
          </div>
          <Svg />
          <div className=" flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 ">
            <nav className="flex " aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div className="flex items-start">
                    <Link
                      className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] "
                      to={`/`}
                    >
                      Home
                    </Link>
                  </div>
                </li>
                <li aria-current="page" className="text-white">
                  /
                  <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                    <Link
                      to={"/Courses"}>
                      Courses
                    </Link>
                  </span>
                </li>
                <li aria-current="page">
                  <div className="flex items-center  text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                      {course.title}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="mx-auto py-4 md:py-6 max-w-7xl ">
        <div className="grid grid-cols-4 lg:grid-cols-12 md:grid-cols-9 gap-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
          <div className='col-span-4 lg:col-span-8  md:col-span-6 '>
            {course?.whatYouWillLearn?.length > 0 && <div className='border p-4'>
              <h2 className='font-bold py-3'>What you will learn</h2>
              <ul className='columns-2 pl-8'>
                {course.whatYouWillLearn.map((item, index) => <li className='list-decimal py-1' key={index}>{item}</li>)}
              </ul>
            </div>}

            {course?.topics?.length > 0 && <div className='border-0 p-4'>
              <h2 className='font-bold py-3'>Course Content</h2>
              <ul>
                {course.topics.map((topic, index) => (
                  <li key={index}>
                    <div onClick={() => handleTopicToggle(index)} className='flex cursor-pointer justify-between items-center px-4 bg-gray-100 py-4 mb-2'>
                      <div>{topic.title} </div>
                      <div className='flex justify-between items-center'><span className='mr-4'> {topic.duration} </span> {expandedTopic === index ? <MinusIcon className='w-3 h-3' /> : <PlusIcon className='w-3 h-3' />}</div>

                    </div>
                    {expandedTopic === index && (
                      <div className='px-6 py-6 bg-gray-50 mb-2'>
                        <p>{topic.description}</p>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>}

            {course?.requirements?.length > 0 && <div className='border-0 p-4'>
              <h2 className='font-bold py-3'>Requirements</h2>
              <ul className='columns-2 pl-8'>
                {course.requirements.map((item, index) => <li className='list-decimal py-1' key={index}>{item}</li>)}
              </ul>
            </div>}

          </div>
          <div className='col-span-4 lg:col-span-4 md:col-span-3 border'>
            <img src={images[course.image]} className='object-fill w-full h-64' alt={course.title} />
            <div className='flex justify-center py-8'>
              <button className='flex w-fit bg-orange-500 hover:bg-orange-700  text-white font-normal text-base py-2 px-6'>Enroll Now</button>
            </div>
            <div className=' px-6 pb-6'>
              <h2 className='font-bold py-3'>This Course Includes</h2>
              <ul className='pl-6'>
                <li className='list-decimal'>Downloads: <span className='font-bold'>{course.courseIncluded.downloads}</span> </li>
                <li className='list-decimal'>Videos: <span className='font-bold'>{course.courseIncluded.videos}</span></li>
                <li className='list-decimal'>Full-Time Access: <span className='font-bold'>{course.courseIncluded.fullTimeAccess ? 'Yes' : 'No'}</span></li>
                <li className='list-decimal'>Certificate of Completion: <span className='font-bold'>{course.courseIncluded.certificateOfCompletion ? 'Yes' : 'No'}</span></li>
              </ul>
            </div>
            <div className='px-6 pb-6 pt-6 border-t'>
              <h2 className='font-bold'>Training 5 or more people?</h2>
              <p>Get your team access to from Smew Acadamy.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
