// import React, { useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
// import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
// import Process from "../assets/images/img.webp";
// import main from "../assets/images/bg.webp";
import main from "../assets/images/bg.webp";
import Svg from "../components/svg";
import Process from "../assets/images/contact.webp";

const colourStyles = {
  control: (styles) => ({ ...styles, lineHeight: "44px" }),
  singleValue: (provided, state) => {
    const margin = "8px 0 0 5px";
    return { ...provided, margin };
  },
};

function Contact() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, e) => {
    const token = process.env.REACT_APP_token;

    fetch(
      "https://techsmewdev.ddns.net/techsmew_website_api/public/api/contact-us",
      {
        method: "POST",
        body: JSON.stringify({
          contact_name: data["fname"] + " " + data["lname"],
          contact_email: data["email"],
          contact_message: data["comments"],
          contact_type: data["interested"]["value"],
          contact_subject: data["interested"]["value"],
          // company: data["company"],
          // joblevel: data["job"]["value"],
          // department: data["department"]["value"],
          // jobfunction: data["functions"]["value"],
          company: "Nil",
          joblevel: "Nil",
          department: "Nil",
          jobfunction: "Nil",
          country: data["country"]["value"],
          state: data["state"],
        }),

        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.status === true) {
          // setStatus('success');
          toast.success("Thank You! We will get back to you soon.");
          e.target.reset();
        } else {
          toast.error("Facing error! Please try again!");
          // setStatus('failure');
        }
      });
  };

  const interested = [
    { value: "Design", label: "Design" },
    { value: "Development", label: "Development" },
    {
      value: "Integration and Middleware",
      label: "Integration and Middleware",
    },
    {
      value: "IT Support and Network Management",
      label: "IT Support and Network Management",
    },
    {
      value: "Data Science and Analytics",
      label: "Data Science and Analytics",
    },
    { value: "Digital Marketing and SEO", label: "Digital Marketing and SEO" },
    {
      value: "Microservices and Modern Architecture",
      label: "Microservices and Modern Architecture",
    },
    {
      value: "Cloud Infrastructure",
      label: "Cloud Infrastructure",
    },
    { value: "AI and Machine Learning", label: "AI and Machine Learning" },
  ];
  // const job = [
  //   { value: "Individual Contributor", label: "Individual Contributor" },
  //   { value: "Developer/Engineer", label: "Developer/Engineer" },
  //   { value: "Architect", label: "Architect" },
  //   { value: "System Administrator", label: "System Administrator" },
  //   { value: "Manager/Sr", label: "Manager/Sr" },
  //   { value: "Director/Sr", label: "Director/Sr" },
  //   { value: "Vice President/SVP", label: "Vice President/SVP" },
  //   { value: "C-Level", label: "C-Level" },
  //   { value: "Press Analyst/Media", label: "Press Analyst/Media" },
  // ];
  // const department = [
  //   { value: "HR", label: "HR" },
  //   { value: "IT", label: "IT" },
  //   { value: "Others", label: "Others" },
  // ];
  // const functions = [
  //   { value: "Developer", label: "Developer" },
  //   { value: "Director", label: "Director" },
  //   { value: "Manager", label: "Manager" },
  //   { value: "Others", label: "Others" },
  // ];
  const country = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Åland Islands", label: "Åland Islands" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "AndorrA", label: "AndorrA" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    {
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
    },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    {
      value: "Congo, The Democratic Republic of the",
      label: "Congo, The Democratic Republic of the",
    },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Cote D'Ivoire", label: "Cote D'Ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    {
      value: "Falkland Islands (Malvinas)",
      label: "Falkland Islands (Malvinas)",
    },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    {
      value: "French Southern Territories",
      label: "French Southern Territories",
    },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    {
      value: "Heard Island and Mcdonald Islands",
      label: "Heard Island and Mcdonald Islands",
    },
    {
      value: "Holy See (Vatican City State)",
      label: "Holy See (Vatican City State)",
    },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    {
      value: "Korea, Democratic People'S Republic of",
      label: "Korea, Democratic People'S Republic of",
    },
    { value: "Korea, Republic of", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    {
      value: "Lao People'S Democratic Republic",
      label: "Lao People'S Democratic Republic",
    },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    {
      value: "Macedonia, The Former Yugoslav Republic of",
      label: "Macedonia, The Former Yugoslav Republic of",
    },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    {
      value: "Micronesia, Federated States of",
      label: "Micronesia, Federated States of",
    },
    { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    {
      value: "Palestinian Territory, Occupied",
      label: "Palestinian Territory, Occupied",
    },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russian Federation", label: "Russian Federation" },
    { value: "RWANDA", label: "RWANDA" },
    { value: "Saint Helena", label: "Saint Helena" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
    },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia and Montenegro", label: "Serbia and Montenegro" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    {
      value: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
    },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    {
      value: "Tanzania, United Republic of",
      label: "Tanzania, United Republic of",
    },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    {
      value: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
    },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ];

  const contactOptions = {
    interested: { required: "Interested option is required" },
    job: { required: "Job level is required" },
    department: { required: "Department is required" },
    functions: { required: "Job function is required" },
    country: { required: "Country is required" },
  };

  return (
    <main className="itInfrastructure_procurement">
      <Helmet>
        {/* Meta Title: Contact Us | Get in Touch with Smew Technologies */}
        <title>Contact Us | Get in Touch with Smew Technologies</title>

        {/* Meta Description: Contact Smew Technologies for inquiries, partnerships and more. Reach out to us and let's discuss how we can collaborate. */}
        <meta
          name="description"
          content="Contact Smew Technologies for inquiries, partnerships and more. Reach out to us and let's discuss how we can collaborate."
        />

        {/* Meta Keywords (for reference): Contact us, contact information, get in touch, inquiries, partnerships */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta
          name="keywords"
          content="Contact us, contact information, get in touch, inquiries, partnerships"
        />
      </Helmet>

      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Unlock Your Potential with Online Learning
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Smew Academy is your gateway to a world of tech knowledge and
                  skills.Explore a wide range of courses taught by industry
                  experts.Unlock your potential and stay ahead in the
                  ever-evolving tech landscape.Start your learning journey with
                  us today!
                </p>
              </div>
            </div>

            <Svg />

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1 md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-xs md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-xs md:text-sm font-medium md:ml-2">
                        Contact
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="md:pb-18">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-4">
            <div className="xl:hidden relative flex justify-center items-center px-6">
              <div className="mt-16 hidden">
                <img src={Process} alt="hero" className="!h-[400px] " />
              </div>
              <img
                src={Process}
                alt="hero"
                className="absolute object-cover w-full h-full "
              />
              <div className="xl:ml-[-30px] justify-center  z-10">
                <div className=" my-14 flex justify-center w-[300px] md:w-[400px] bg-[#492196]">
                  <div className=" mx-0 xl:p-0 py-6 px-4 lg:mx-4 md:mx-2 my-6 md:mb-0 xl:m-10">
                    <p className="text-base font-normal text-white mb-6">
                      We help organizations to tranform their enterprises into
                      digital platforms. We build the productivity tools,
                      support and ensure to continue the business as per the
                      plan during hectic situations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hidden xl:flex  justify-center items-center px-6"
              style={{
                backgroundImage: `url(${main})`,
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="mt-16">
                <img
                  src={Process}
                  alt="hero"
                  className="!h-[350px] object-cover "
                />
              </div>
              <div className="xl:ml-[-30px] justify-center">
                <div className=" mt-14 flex justify-center w-[400px]  bg-[#492196]">
                  <div className="mx-0 xl:p-0 pt-6 lg:mx-4 md:mx-2 my-6 md:mb-0 xl:m-10">
                    <p className="text-base font-normal text-white pt-6 mb-6">
                      We help organizations to tranform their enterprises into
                      digital platforms. We build the productivity tools,
                      support and ensure to continue the business as per the
                      plan during hectic situations.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white mx-6 xl:p-0 pt-6 xl:mr-10 lg:mx-10 md:mx-8 my-10 ">
              <h1 className="text-2xl font-medium mb-6">Get in Touch</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative mb-3 mt-2 pt-2">
                  <Controller
                    name="interested"
                    control={control}
                    defaultValue=""
                    rules={contactOptions.interested}
                    render={({ field }) => (
                      <Select
                        styles={colourStyles}
                        options={interested}
                        {...field}
                        label="I'm interested in"
                        placeholder=""
                      />
                    )}
                  />
                  <label
                    htmlFor="interested"
                    className="absolute ml-2 mt-3 left-0 top-0 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    I'm interested in
                  </label>
                  {errors.interested && (
                    <div className="text-sm text-red-500">
                      Interested option is required
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative mb-0">
                    <input
                      type="text"
                      className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                      placeholder="John"
                      name="fname"
                      {...register("fname", { required: true })}
                    />
                    <label
                      htmlFor="fname"
                      className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      First Name
                    </label>
                    {errors.fname && (
                      <div className="text-sm text-red-500">
                        First name is required
                      </div>
                    )}
                  </div>
                  <div className="relative mb-0">
                    <input
                      type="text"
                      className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                      placeholder="Peter"
                      name="lname"
                      {...register("lname", { required: true })}
                    />
                    <label
                      htmlFor="lname"
                      className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Last Name
                    </label>
                    {errors.lname && (
                      <div className="text-sm text-red-500">
                        Last name is required
                      </div>
                    )}
                  </div>
                  <div className="relative mt-0">
                    <input
                      type="text"
                      className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                      placeholder="business@mail.com"
                      name="email"
                      {...register("email", { required: true })}
                    />
                    <label
                      htmlFor="email"
                      className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Email
                    </label>
                    {errors.email && (
                      <div className="text-sm text-red-500">
                        Email address is required
                      </div>
                    )}
                  </div>
                  {/* <div className="relative mt-0">
                    <input
                      type="text"
                      className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                      placeholder="Company"
                      name="company"
                      {...register("company", { required: true })}
                    />
                    <label
                      htmlFor="company"
                      className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Company
                    </label>
                    {errors.company && (
                      <div className="text-sm text-red-500">
                        Company name is required
                      </div>
                    )}
                  </div> */}

                  <div className="relative mt-0">
                    <input
                      type="number"
                      className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                      placeholder="Business Phone"
                      name="phone"
                      {...register("phone", {
                        required: true,
                        minLength: 10,
                        // minLength: 10,
                      })}
                    />
                    <label
                      htmlFor="phone"
                      className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Phone
                    </label>
                    {errors.phone && errors === "required" && (
                      <div className="text-sm text-red-500">
                        10 digit phone number is required
                      </div>
                    )}
                    {errors.phone && errors.phone.type === "minLength" && (
                      <div className="text-sm text-red-500">
                        Phone number must be at least 10 digits long
                      </div>
                    )}
                    {errors.phone && errors.phone.type === "maxLength" && (
                      <div className="text-sm text-red-500">
                        Phone number cannot exceed 10 digits
                      </div>
                    )}
                  </div>

                  {/* <div className="relative">
                    <div className="form-floating form-floating-custom">
                      <Controller
                        name="job"
                        control={control}
                        defaultValue=""
                        rules={contactOptions.job}
                        render={({ field }) => (
                          <Select
                            styles={colourStyles}
                            options={job}
                            {...field}
                            label="Job Level"
                            placeholder=""
                          />
                        )}
                      />
                      <label
                        htmlFor="job"
                        className="absolute ml-4 mt-3 pt-1 left-0 -top-3 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Job Level
                      </label>
                      {errors.job && (
                        <div className="text-sm text-red-500">
                          Job Level is required
                        </div>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="relative">
                    <div className="form-floating form-floating-custom">
                      <Controller
                        name="department"
                        control={control}
                        defaultValue=""
                        rules={contactOptions.department}
                        render={({ field }) => (
                          <Select
                            styles={colourStyles}
                            options={department}
                            {...field}
                            label="Department"
                            placeholder=""
                          />
                        )}
                      />
                      <label
                        htmlFor="department"
                        className="absolute ml-4 mt-3 pt-1 left-0 -top-3 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Department
                      </label>
                      {errors.department && (
                        <div className="text-sm text-red-500">
                          Department is required
                        </div>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="relative">
                    <div className="form-floating form-floating-custom">
                      <Controller
                        name="functions"
                        control={control}
                        defaultValue=""
                        rules={contactOptions.functions}
                        render={({ field }) => (
                          <Select
                            styles={colourStyles}
                            options={functions}
                            {...field}
                            label="Job Function"
                            placeholder=""
                          />
                        )}
                      />
                      <label
                        htmlFor="functions"
                        className="absolute ml-4 mt-3 pt-1 left-0 -top-3 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Job Function
                      </label>
                      {errors.functions && (
                        <div className="text-sm text-red-500">
                          Job Function is required
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="relative">
                    <div className="form-floating form-floating-custom">
                      <Controller
                        name="country"
                        control={control}
                        defaultValue=""
                        rules={contactOptions.country}
                        render={({ field }) => (
                          <Select
                            styles={colourStyles}
                            options={country}
                            {...field}
                            label="Country"
                            placeholder=""
                          />
                        )}
                      />
                      <label
                        htmlFor="country"
                        className="absolute ml-4 mt-3 pt-1 left-0 -top-3 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Country
                      </label>
                      {errors.country && (
                        <div className="text-sm text-red-500">
                          Country is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative">
                    <div className="form-floating form-floating-custom">
                      <input
                        type="text"
                        className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                        placeholder="State"
                        name="state"
                        {...register("state", { required: true })}
                      />
                      <label
                        htmlFor="state"
                        className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        State
                      </label>
                      {errors.state && (
                        <div className="text-sm text-red-500">
                          State is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="relative mt-3">
                  <textarea
                    className="peer placeholder-transparent h-14 pl-4 pt-5 w-full border rounded-md border-gray-300 text-gray-900 focus:outline-none focus:border-[#0d6efd]"
                    placeholder="Leave a comment here"
                    name="comments"
                    {...register("comments", { required: true })}
                  ></textarea>
                  <label
                    htmlFor="Comments"
                    className="absolute left-0 mt-2 mx-4 top-0 text-gray-600 text-sm  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    Comments
                  </label>
                  {errors.comments && (
                    <div className="text-sm text-red-500">
                      Comments required
                    </div>
                  )}
                </div>

                <div className="flex flex-col mb-3 mt-3 ">
                  <button className="bg-[#0d6efd] w-fit hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full">
                    <span>Submit</span>
                    {/* <div className="flex ml-2 hover:text-white">
                      <ArrowRightOnRectangleIcon className="h-5 w-5 text-[#492196] hover:text-[#492196] bg-white  hover:bg-[#492196]" />
                    </div> */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
    </main>
  );
}

export default Contact;
