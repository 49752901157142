import Design from "../assets/images/design.webp";
import Graphic from "../assets/images/graphic-designing.webp";
import Node from "../assets/images/node-js.webp";
import MicroservicesArchitecture from "../assets/images/microservicesarchitecture.webp";
import AWSFundamentals from "../assets/images/aws-fundamental.webp";
import ReactJs from "../assets/images/react-js.webp";
import Photoshop from "../assets/images/photoshop.webp";
import Illustrator from "../assets/images/illustrator.webp";
import UIUX from "../assets/images/ui-and-ux.webp";
import Prototype from "../assets/images/prototype.webp";
import UIPrinciples from "../assets/images/ui.webp";
import Branding from "../assets/images/branding-and-logo-design.webp";
import MotionGraphics from "../assets/images/animation.webp";
import DigitalMarketing from "../assets/images/digitalmarketing.webp";
import SEO from "../assets/images/seo.webp";
import SocialMediaMarketing from "../assets/images/SocialMediaMarketing.webp";
import PPCAdvertising from "../assets/images/PPCAdvertising.webp";
import ContentMarketingStrategy from "../assets/images/contentmarketingstrategy.webp";
import EmailMarketing from "../assets/images/emailmarketing.webp";
import OnlineReputationManagement from "../assets/images/onlinereputationmanagement.webp";
import VideoMarketingYouTubeSEO from "../assets/images/videomarketingyoutubeseo.webp";
import MobileMarketingASO from "../assets/images/mobilemarketing.webp";
import PythonProgramming from "../assets/images/pythonprogramming.webp";
import WebDevelopment from "../assets/images/webdevelopment.webp";
import FullStackWebDev from "../assets/images/fullstackwebdev.webp";
import MobileAppDevelopment from "../assets/images/mobileappdevelopment.webp";
import JavaProgramming from "../assets/images/javaprogramming.webp";
import CSharpDotNet from "../assets/images/csharpdotnet.webp";
import FrontendFrameworks from "../assets/images/frontendframeworks.webp";
import PHPDevelopmentAndFrameworks from "../assets/images/phpdevelopmentandframeworks-1.webp";
import BackEndFrameworks from "../assets/images/backendframeworks.webp";
import DataStructuresAlgorithms from "../assets/images/datastructuresalgorithms.webp";
import MuleSoft from "../assets/images/mulesoft.webp";
import MuleSoftImplementation from "../assets/images/mulesoftimplementation.webp";
import Integration from "../assets/images/integration.webp";
import ESB from "../assets/images/esb.webp";
import APIManagement from "../assets/images/apimanagement.webp";
import IntegrationArchitecture from "../assets/images/react-js.webp";
import MicroservicesArchitectureFundamentals from "../assets/images/react-js.webp";
import ContainerizationOrchestration from "../assets/images/containerization-and-orchestration.webp";
import ServiceMesh from "../assets/images/servicemesh.webp";
import APIGateway from "../assets/images/apigateway.webp";
import EventDriven from "../assets/images/event-driven-architecture.webp";
import Serverless from "../assets/images/serverless.webp";
import Microservices from "../assets/images/microservices.webp";
import CompTIA from "../assets/images/comptia.webp";
import CompTIAA from "../assets/images/comptiaanetwork.webp";
import CompTIANetwork from "../assets/images/comptiaa.webp";
import CCNA from "../assets/images/react-js.webp";
import WindowsServer from "../assets/images/react-js.webp";
import LinuxAdmin from "../assets/images/react-js.webp";
import NetworkSecurity from "../assets/images/react-js.webp";
import CloudNetworking from "../assets/images/cloudnetworking.webp";
import ITIL from "../assets/images/itil.webp";
import AWS from "../assets/images/react-js.webp";
import AWSA from "../assets/images/react-js.webp";
import AWSDA from "../assets/images/react-js.webp";
import GoogleCloud from "../assets/images/react-js.webp";
import GoogleCloudCA from "../assets/images/react-js.webp";
import MicrosoftAzure from "../assets/images/react-js.webp";
import MicrosoftAzureAdmin from "../assets/images/azure-administrator-associate.webp";
import DevOpsCICD from "../assets/images/gitlab.webp";
import DataScienceAnalytics from "../assets/images/react-js.webp";
import PythonDataScience from "../assets/images/python-for-data-science.webp";
import TableauDataViz from "../assets/images/data-visualization.webp";
import MachineLearning from "../assets/images/mlp.webp";
import BigData from "../assets/images/big-data-fundamentals.webp";
import SQL from "../assets/images/sql.webp";
import ITAsset from "../assets/images/servicenow.webp";
import DataWarehousing from "../assets/images/data-warehousing-and-business-intelligence.webp";
import ArtificialIntelligence from "../assets/images/artificial-intelligence.webp";
import MachineLearningModels from "../assets/images/machine-learning-algorithms-and-models.webp";
import DeepLearning from "../assets/images/deep-learning-and-neural-networks.webp";
import NLP from "../assets/images/natural-language-processing.webp";
import ComputerVision from "../assets/images/computer-vision-and-image-recognition.webp";
import ReinforcementLearning from "../assets/images/reinforcement-learning.webp";






export const images = {
  Design,
  Graphic,
  Node,
  ReactJs,
  Photoshop,
  Illustrator,
  UIUX,
  UIPrinciples,
  Branding,
  MotionGraphics,
  DigitalMarketing,
  SEO,
  SocialMediaMarketing,
  PPCAdvertising,
  ContentMarketingStrategy,
  EmailMarketing,
  OnlineReputationManagement,
  VideoMarketingYouTubeSEO,
  MobileMarketingASO,
  PythonProgramming,
  WebDevelopment,
  FullStackWebDev,
  MobileAppDevelopment,
  JavaProgramming,
  CSharpDotNet,
  FrontendFrameworks,
  PHPDevelopmentAndFrameworks,
  BackEndFrameworks,
  DataStructuresAlgorithms,
  MuleSoft,
  MuleSoftImplementation,
  Integration,
  ESB,
  APIManagement,
  IntegrationArchitecture,
  MicroservicesArchitecture,
  MicroservicesArchitectureFundamentals,
  ContainerizationOrchestration,
  ServiceMesh,
  APIGateway,
  EventDriven,
  Serverless,
  Microservices,
  CompTIA,
  CompTIAA,
  CompTIANetwork,
  CCNA,
  WindowsServer,
  LinuxAdmin,
  NetworkSecurity,
  CloudNetworking,
  ITIL,
  AWS,
  AWSA,
  AWSDA,
  GoogleCloud,
  GoogleCloudCA,
  MicrosoftAzure,
  MicrosoftAzureAdmin,
  DevOpsCICD,
  DataScienceAnalytics,
  PythonDataScience,
  TableauDataViz,
  MachineLearning,
  BigData,
  SQL,
  ITAsset,
  DataWarehousing,
  ArtificialIntelligence,
  MachineLearningModels,
  DeepLearning,
  NLP,
  ComputerVision,
  ReinforcementLearning,
  AWSFundamentals,
  Prototype
};
