import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import "../index.css";

function Navigation() {
  const dropdownRef = useRef(null);
  const dropdownSolutionRef = useRef(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleHamburger = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const toggleSolution = () => {
    if (activeDropdown === "solution") {
      setActiveDropdown(null);
    } else {
      setActiveDropdown("solution");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeDropdown = useCallback((value) => {
    if (value !== undefined) {
      scrollToTop();
    }
    setActiveDropdown(null);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownSolutionRef.current &&
        !dropdownSolutionRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeDropdown]);

  return (
    <main className="about max-w-7xl ">
      <div className="z-30 w-full bg-white  fixed top-0">
        <div className="max-w-7xl mx-auto mt-0">
          <div className="items-center">
            <nav className="navbar fixed-top">
              <div className="lg:mx-10 xl:mx-0 md:ml-8 ml-6 grid grid-cols-5 fixed-top">
                <div className="flex col-span-1">
                  <NavLink to="/">
                    <img
                      src="/assets/images/logo.svg"
                      alt="logo"
                      className="w-[12vh] pt-4 pb-4"
                    />
                  </NavLink>
                </div>

                <div
                  className="mr-[2rem] ml-[15rem] md:ml-[31.5rem] mt-3 w-[3.25rem] lg:hidden"
                  onClick={toggleHamburger}
                >
                  <div className="py-[0.5rem] px-[0.75rem] space-y-2 bg-[#492196] rounded shadow justify-items-end">
                    <span className="block w-7 h-0.5 bg-white animate-pulse"></span>
                    <span className="block w-7 h-0.5 bg-white animate-pulse"></span>
                    <span className="block w-7 h-0.5 bg-white animate-pulse"></span>
                  </div>
                  <div
                    className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}
                  />
                </div>

                <div
                  className={` space-x-0 lg:flex col-span-5  lg:col-span-4 xl:justify-end lg:justify-end ${
                    isMenuOpen ? "block" : "hidden"
                  }`}
                >
                  <ul className="space-x-0  pb-2 flex-nowrap lg:flex xl:space-x-10 lg:space-x-6 items-center">
                    <li
                      className="text-sm cursor-pointer py-[2px]"
                      onClick={(e) => closeDropdown(e.target)}
                    >
                      <NavLink
                        to="/about-us"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li
                      className="group relative w-auto"
                      ref={dropdownSolutionRef}
                    >
                      <span
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod  cursor-pointer flex items-center"
                        onClick={toggleSolution}
                      >
                        <NavLink
                          to="courses"
                          onClick={(e) => closeDropdown(e.target)}
                        >
                          Courses
                        </NavLink>

                        {activeDropdown === "Courses" ? (
                          <ChevronUpIcon className="ml-1 h-6 w-6 text-black pt-1" />
                        ) : (
                          <ChevronDownIcon className="ml-1 h-6 w-6 text-black pt-1" />
                        )}
                      </span>

                      <div
                        className={`relative lg:fixed ${
                          activeDropdown === "solution" ? "block" : "hidden"
                        } bg-white mt-2 py-0 shadow-md border-b-4 border-[#492196] w-full left-0 `}
                        style={{
                          overflowY: "auto",
                          maxHeight: "90vh",
                          marginTop: "10px",
                        }}
                      >
                        <div className="grid grid-cols-1 gap-4 p-2 lg:p-6 lg:grid-cols-5 md:grid-cols-3">
                          <div>
                            <div className="font-semibold text-gray-700 pt-2">
                              <span onClick={(e) => closeDropdown(e.target)}>
                                Design
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <NavLink to="/course-details/adobe-photoshop-masterclass">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Adobe Photoshop Masterclass
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/adobe-illustrator-for-graphic-design">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Adobe Illustrator for Graphic Design
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/figma-for-design-and-prototyping">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Figma for Design and Prototyping
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/branding-and-logo-design">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Branding and Logo Design
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/motion-graphics-and-animation">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  Motion Graphics and Animation
                                </li>
                              </NavLink>
                            </ul>
                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/design"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Digital Marketing and SEO
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <NavLink to="/course-details/digital-marketing-fundamentals">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Digital Marketing Fundamentals
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/search-engine-optimization">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Search Engine Optimization (SEO)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/social-media-marketing">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Social Media Marketing
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/pay-per-click-advertising">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Pay-Per-Click (PPC) Advertising
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/content-marketing-strategy">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Content Marketing Strategy
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/email-marketing-and-automation">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Email Marketing and Automation
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/online-reputation-management">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Online Reputation Management
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/video-marketing-and-youtube-seo">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Video Marketing and YouTube SEO
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/mobile-marketing-and-app-store-optimization">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  Mobile Marketing and App Store Optimization
                                  (ASO)
                                </li>
                              </NavLink>
                            </ul>
                          </div>

                          <div>
                            <div className="font-semibold text-gray-700">
                              <span
                                to="solutions/it-consulting"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Development
                              </span>
                            </div>

                            <div className="border-t-4 w-10 border-[#f89539] my-2"></div>

                            <ul>
                              <NavLink to="/course-details/introduction-to-programming-with-python">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Introduction to Programming with Python
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/web-development-fundamentals">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Web Development Fundamentals (HTML, CSS,
                                  JavaScript)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/full-stack-web-development">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Full-Stack Web Development
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/mobile-app-development">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Mobile App Development (iOS/Android)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/java-programming-development">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Java Programming and Application Development
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/csharp-dotnet-development">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  C# and .NET Development
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/frontend-frameworks-react-angular-vue">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Frontend Frameworks (React, Angular, Vue.js)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/back-end-frameworks">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Back-End Frameworks (Node.js, Django, Ruby on
                                  Rails)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/php-development-and-frameworks">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  PHP Development and Frameworks (Laravel,
                                  Symfony)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/data-structures-and-algorithms">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Data Structures and Algorithms for Developers
                                </li>
                              </NavLink>
                            </ul>
                          </div>

                          <div>
                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/platform"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Integration and Middleware
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>
                            <ul>
                              <NavLink to="/course-details/mulesoft-anypoint-platform-fundamentals">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  MuleSoft Anypoint Platform Fundamentals
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/mulesoft-api-design-and-implementation">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  MuleSoft API Design and Implementation
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/integration-architecture-and-patterns">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Integration Architecture and Patterns
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/enterprise-service-bus-development">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Enterprise Service Bus (ESB) Development
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/api-management-and-governance">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  API Management and Governance
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/integration-architecture-and-patterns">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  Integration Architecture and Patterns
                                </li>
                              </NavLink>
                            </ul>

                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/platform"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Microservices and Modern Architecture
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>
                            <ul>
                              <NavLink to="/course-details/containerization-and-orchestration">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Containerization and Orchestration (Docker,
                                  Kubernetes)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/service-mesh">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Service Mesh (Istio, Linkerd)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/api-gateway-and-edge-services">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  API Gateway and Edge Services
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/event-driven-architecture">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Event-Driven Architecture
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/serverless-computing">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Serverless Computing
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/modernizing-monolithic-to-microservices">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Modernizing Monolithic Applications to
                                  Microservices
                                </li>
                              </NavLink>
                            </ul>
                          </div>
                          <div>
                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/digital-marketing"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                IT Support and Network Management
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <NavLink to="/course-details/comptia-it-fundamentals">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  CompTIA IT Fundamentals (ITF+)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/linux-system-administration">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Linux System Administration
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/network-security-and-firewall-management">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Network Security and Firewall Management
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/cloud-networking-and-virtualization">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Cloud Networking and Virtualization
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/it-service-management-itil">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  IT Service Management (ITIL)
                                </li>
                              </NavLink>
                            </ul>
                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/digital-marketing"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Cloud Infrastructure
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>
                            <ul>
                              <NavLink to="/course-details/aws-fundamentals">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  AWS Fundamentals
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/microsoft-azure-fundamentals">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Microsoft Azure Fundamentals
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/devops-and-continuous-integration-deployment">
                                {" "}
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  DevOps and Continuous Integration/Deployment
                                  (CI/CD)
                                </li>
                              </NavLink>
                            </ul>
                          </div>
                          <div>
                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/it-infrastructure"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Data Science and Analytics
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>
                            <ul>
                              <NavLink to="/course-details/python-for-data-science">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  Python for Data Science
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/data-visualization-with-tableau">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Data Visualization with Tableau
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/machine-learning-and-predictive-analytics">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Machine Learning and Predictive Analytics
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/big-data-fundamentals">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Big Data Fundamentals (Hadoop, Spark)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/sql-and-database-management">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  SQL and Database Management
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/it-asset-management">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  IT Asset Management
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/data-warehousing-and-business-intelligence">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Data Warehousing and Business Intelligence
                                </li>
                              </NavLink>
                            </ul>

                            <div className="font-semibold text-gray-700 pt-2">
                              <span
                                to="solutions/it-infrastructure"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                AI and Machine Learning
                              </span>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>
                            <ul>
                              <NavLink to="/course-details/introduction-to-artificial-intelligence">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  {" "}
                                  Introduction to Artificial Intelligence
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/machine-learning-algorithms-and-models">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Machine Learning Algorithms and Models
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/deep-learning-and-neural-networks">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Deep Learning and Neural Networks
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/natural-language-processing">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Natural Language Processing (NLP)
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/computer-vision-and-image-recognition">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Computer Vision and Image Recognition
                                </li>
                              </NavLink>
                              <NavLink to="/course-details/reinforcement-learning">
                                <li
                                  className="text-sm cursor-pointer py-[2px]"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Reinforcement Learning
                                </li>
                              </NavLink>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="space-x-4">
                      <NavLink
                        to="/mentors"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        Mentors
                      </NavLink>
                    </li>

                    <li className="space-x-4">
                      <NavLink
                        to="/knowledge-center"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod md:ml-0"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        Knowledge Center
                      </NavLink>
                    </li>
                    <li className="space-x-4">
                      <NavLink
                        to="/contact"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod md:ml-0"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        Contact Us
                      </NavLink>
                    </li>

                    <li className="space-x-4 xl:mr-[7rem] lg:mr-5 lg:mb-0 mb-4">
                      <NavLink
                        to="contact"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod "
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        {/* Contact */}
                      </NavLink>
                    </li>
                  </ul>
                  <ul className=" flex md:pb-6 lg:pl-0 pl-0 pb-6 lg:pb-0 flex-nowrap  col-span-2 items-center">
                    <li
                      className="text-sm cursor-pointer py-[2px]"
                      onClick={(e) => closeDropdown(e.target)}
                    >
                      <NavLink
                        to="/contact"
                        className="flex bg-orange-500 hover:bg-orange-700 text-white font-normal text-base py-2 px-6 rounded-full"
                      >
                        {" "}
                        <LockClosedIcon className=" w-6 h-6 text-white mr-2" />{" "}
                        Login / Register{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {activeDropdown && (
              <button
                onClick={(e) => closeDropdown(e.target)}
                className="absolute p-2 bg-orange-500 rounded-full px-4 text-white right-4"
              >
                {" "}
                X
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Navigation;
