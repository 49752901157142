import React from "react";
import { NavLink } from "react-router-dom";
import Mentor from "../assets/images/mentor.webp";
import CTA from "../assets/images/cta.webp";

function Mentors() {
  return (
    <main className="design_ui_ux">
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[140px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center pb-10">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Your Gateway to Learning: Explore Our Range of Courses
                </h1>
                <p className="mb-12 md:ml-10 px-4 mx-auto font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Embark on a transformative journey through the boundless realm
                  of knowledge with our wide array of courses. At Smew Academy,
                  we are your trusted gateway to learning, opening doors to
                  endless opportunities for personal and professional growth.
                  Your quest for learning excellence commences right here.
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-2 md:px-6 pt-1 md:py-2 rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 mr-10">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-normal md:font-medium text-gray-100 hover:text-[#f89539]"
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-normal md:font-medium md:ml-2">
                        Mentors
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white text-black">
        <div className="max-w-7xl mx-auto my-10 md:pb-18">
          <div className="py-6 md:py-10 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-6 mx-6  xl:mx-0 lg:mx-10 md:mx-8">
            <div className="flex justify-center items-center">
              <div className="xl:p-0 pt-6">
                <h1 className="text-[2rem] text-black font-light mb-1">
                  Mentors of Smew Acadamy
                </h1>
                <div className="mb-8 ml-0 md:ml-0 mt-2">
                  <div className="flex items-center">
                    <p className="text-base text-black">
                      At Mentors of Smew Academy, we are dedicated to empowering
                      individuals with the skills and knowledge they need to
                      excel in the dynamic worlds of technology and design. Our
                      mission is to bridge the gap between your aspirations and
                      your future success. We specialize in full-stack
                      development and UX/UI design. Our goal is to help you
                      succeed in technology and design.
                    </p>
                  </div>
                </div>
                <div className="flex mb-3 mt-3 text-[#e75828]">
                  <NavLink
                    to="/contact"
                    className="flex mb-3 mt-3 text-[#e75828]"
                  >
                    <button className="bg-[#e75828] border-2 border-[#e75828] hover:bg-orange-700 text-white hover:text-white py-2 px-6 flex items-center rounded-full">
                      <span>Join Us</span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="md:flex items-center rounded-lg mx-0 xl:p-0 xl:mx-0 lg:mr-0 md:mx-8 space-y-4 md:justify-center">
              <div className="relative lg:justify-end">
                <img src={Mentor} alt="testimage" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="body-font bg-gray-100">
        <div className="container px-6 py-20 mx-auto md:px-8 lg:px-10 xl:px-0">
          <div className="flex flex-col text-center w-full mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              How Efficient We Are?
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Our mentors are highly efficient in guiding you through the world
              of development and design. They have real-world experience and a
              passion for teaching. They work closely with you to make sure you
              understand everything and become skilled in these fields. Their
              goal is to help you succeed and reach your full potential.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-white py-5">
        <div className="max-w-7xl mx-auto ">
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-2 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0 ">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95 py-20">
              <h1 className="text-2xl font-normal text-black mb-2 text-center">
                How we bring them in
              </h1>
              <p className="text-black font-normal text-base  text-center">
                We connect with industry experts who have real-world knowledge
                of development and design. We foster a community where mentors
                willingly contribute their expertise to empower future
                professionals.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95 py-20">
              <h1 className="text-2xl font-normal text-black mb-2 text-center">
                What we offer them
              </h1>
              <p className="text-black font-normal text-base  text-center">
                We provide them with opportunities to share their knowledge and
                guide aspiring developers and designers. We support mentors in
                expanding their expertise, staying updated with industry trends,
                and enhancing their teaching skills.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#E4D6FF]">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Mentors Who Shape Success
                </p>
                <h2 className="text-[1.5rem] font-light text-black pb-3">
                  In the journey of every accomplished learner, there's a mentor
                  who paves the path to success.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#E75828] border-2 border-[#e75828] hover:bg-orange-700 mt-6 mb-2  text-white py-2 text-base px-10 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Join Us
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} className="rounded-xl" alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Mentors;
