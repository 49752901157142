import React from "react";
import Main from "../assets/images/main-banner.webp";
import { images } from '../components/CourseImages';
import coursesData from '../data/courses.json'; 
import { NavLink } from 'react-router-dom';
import Figma from "../assets/images/icons/icon-figma.png";
import AI from "../assets/images/icons/icon_ai.png";
import AWS from "../assets/images/icons/icon_aws.png";
import DC from "../assets/images/icons/icon_dc.png";
import GIT from "../assets/images/icons/icon_git.png";
import JS from "../assets/images/icons/icon_js.png";
import PS from "../assets/images/icons/icon_ps.png";
import REACT from "../assets/images/icons/icon_react.png";
import SK from "../assets/images/icons/icon_sk.png";


import {
  AcademicCapIcon,
  LifebuoyIcon,
  BookmarkIcon,
  ClockIcon,
  ArrowTrendingUpIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

function Home() {
  function styledConsoleText(text, fontSize) {
    const blueStyle = "color: blue;"; // Blue text color
    const fontSizeStyle = `font-size: ${fontSize}px;`; // Font size in pixels
    const border = "border:double";

    // Combine the styling with the text
    const styledText = `%c${text}`;

    // Log the styled text to the console
    console.log(styledText, `${blueStyle}${fontSizeStyle} ${border}`);
  }

  // Call the custom function with your desired text and font size
  styledConsoleText(" Apply Job at hr@Smew.com ", 49); // Adjust the font size as needed

  return (
    <main className="home_main">
      <section className="relative overflow-hidden bg-[#492196]">
        <div className="mx-auto mt-20 md:mt-0 mb-4 md:mb-0">
          <div className="grid grid-cols-1 items-center md:grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="justify-end w-full h-full md:hidden hidden lg:flex">
              {<img src={Main} alt="home main" className="w-full bg-cover mt-16" />}
            </div>

            <div className="xl:ml-[1rem] mt-30 md:my-28 mx-6 xl:mx-0 lg:mx-10 md:mx-8 xl:mr-80">
              <h1 className="text-[28px] xl:text-[55px] font-bold text-center md:text-left text-white mx-auto mb-6 xl:leading-[60px] leading-[40px]">
                Begin Your Career Journey with Us
              </h1>
              <p className="text-md md:text-xl text-center md:text-left text-[#e3e3e3] mx-auto">
                Join the revolution. Craft your tech destiny with us. Explore
                limitless possibilities in the world of IT
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font py-12 row-auto mx-6 lg:mx-10 md:mx-8 xl:mx-0">
        <div className="container  max-w-7xl mx-auto">
          <h1 className="sm:text-3xl text-2xl text-center font-medium title-font mb-2 text-gray-900">
            Master IT with Our Featured Courses
          </h1>
          <p className=" w-full leading-relaxed max-w-5xl mx-auto text-center text-gray-500 py-4">
            Our carefully selected offerings cover a range of in-demand topics,
            providing you with the edge you need to thrive in this ever-evolving
            field. Dive in and start your transformation today. Each program is
            designed to equip you with practical skills and knowledge, ensuring
            you're well-prepared for the challenges of the tech industry. Stay
            relevant and take your career to new heights.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 md:justify-items-center lg:justify-items-start gap-4">
            {coursesData.slice(0, 4).map((course) => (
              <div key={course.id} className="py-4 group">
                <NavLink to={`/course-details/${course.slug}`}>
                  <figure className="relative max-w-sm">
                    <img
                      src={images[course.image]}
                      className="transform scale-100 group-hover:scale-105"
                      alt="description"
                    />
                    <figcaption className="text-black bg-white w-full h-16 -mb-2 align-middle absolute bottom-[0.1px] left-0 opacity-90 group-hover:bg-orange-500 group-hover:text-white group-hover:scale-105">
                      <p className="opacity-100 p-3 text-md text-center">
                        {course.title}
                      </p>
                    </figcaption>
                  </figure>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font mx-auto md:pb-18 bg-gray-50 py-16 px-6 lg:px-10 md:px-8 xl:px-0">
        <div className="mx-auto max-w-7xl">
          <h1 className="sm:text-3xl text-2xl text-center font-medium title-font mb-2 text-gray-900">
            Standout Course Features
          </h1>
          <p className="  leading-relaxed max-w-5xl mx-auto  text-center text-gray-500 py-4 ">
            Embark on a learning journey like never before with our meticulously
            chosen IT courses. From programming languages to cloud computing,
            each course is crafted to offer you a comprehensive understanding of
            the latest technologies. Seize the opportunity to enhance your
            skills and future-proof your career.
          </p>
          <div className="container py-5 flex flex-wrap">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-4 ">
              <div className="p-0 w-full bg-gray-50 shadow-lg ">
                <div className="flex  border-opacity-50 p-8 sm:flex-row flex-col h-full">
                  <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full  border-2 border-gray-200 bg-gray-50 text-indigo-500 flex-shrink-0">
                    <ClockIcon className="w-8 h-8 text-pink-500" />
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                      Real-time Experts as Trainers
                    </h2>
                    <p className="leading-relaxed text-base">
                      Learn from the best in the business who really want to
                      help you. Get personal guidance from these experts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-0 w-full bg-gray-50  shadow-lg">
                <div className="flex border-opacity-50 p-8 sm:flex-row flex-col h-full">
                  <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full  border-2 border-gray-200 bg-gray-50 text-indigo-500 flex-shrink-0">
                    <ArrowTrendingUpIcon className="w-8 h-8 text-red-500" />
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                      Live Project
                    </h2>
                    <p className="leading-relaxed text-base">
                      Get a chance to work on real projects, gaining valuable
                      hands-on experience.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-4 mt-3">
              <div className="p-0 -full bg-gray-50 shadow-lg ">
                <div className="flex  rounded-lg  border-opacity-50 p-8 sm:flex-row flex-col h-full">
                  <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full  border-2 border-gray-200 bg-gray-50 text-indigo-500 flex-shrink-0">
                    <BookmarkIcon className="w-8 h-8 text-purple-500" />
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                      Certification
                    </h2>
                    <p className="leading-relaxed text-base">
                      Earn your certification and prepare to pass global
                      certifications.
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-0 w-full bg-gray-50 shadow-lg">
                <div className="flex  rounded-lg  border-opacity-50 p-8 sm:flex-row flex-col h-full">
                  <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full  border-2 border-gray-200 bg-gray-50 text-indigo-500 flex-shrink-0">
                    <UserCircleIcon className="w-8 h-8 text-orange-500" />
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                      Personalized Learning Plans
                    </h2>
                    <p className="leading-relaxed text-base">
                      We offer personalized learning plans that adapt to your
                      individual pace and preferences.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font  p-6">
        <div className="container px-0 max-w-7xl mx-auto my-2 md:my-10 lg:my-15 md:pb-18 bg-[#f9fafb] rounded-lg">

          <div className="flex flex-wrap text-center ">
            <div className="p-4 md:w-2/4 lg:w-1/4 sm:w-1/2 w-full bg-[#f1f1f1] border-y-4 border-t-0 drop-shadow border-indigo-600 text-left">
              <div className=" px-4 py-6 rounded-lg">
                <p className="title-font leading-relaxed mb-3 text-black font-bold">Get Free Concession</p>
                <h4 className=" font-extralight text-2xl text-black">Become a Certified Designer / Developer with Smew Academy.</h4>

              </div>
            </div>
            <div className="p-4 md:w-2/4 lg:w-1/4 sm:w-1/2 border-y-4 border-t-0 border-indigo-400 drop-shadow  w-full">
              <div className="px-4 py-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-white text-indigo-500 mb-4">
                  <AcademicCapIcon className="w-6 h-6" />
                </div>
                <h2 className="title-font font-medium  text-black">
                  Updated Curriculum
                </h2>
                <p className="leading-relaxed text-black ">
                  Dive into the world of tomorrow with our enhanced curriculum
                </p>
              </div>
            </div>
            <div className="p-4 md:w-2/4 lg:w-1/4 sm:w-1/2 border-y-4 border-t-0  border-indigo-200	drop-shadow  w-full">
              <div className="px-4 py-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-white text-indigo-500 mb-4">
                  <LifebuoyIcon className="w-6 h-6" />
                </div>
                <h2 className="title-font font-medium  text-black">
                  Experience Mentoring
                </h2>
                <p className="leading-relaxed text-black ">
                  Discover the powerful impact of mentorship. Empower yourself
                  with knowledge.
                </p>
              </div>
            </div>
            <div className="p-4 md:w-2/4 lg:w-1/4 sm:w-1/2 border-y-4 border-t-0 border-indigo-100	drop-shadow w-full">
              <div className="px-4 py-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-white text-indigo-500 mb-4">
                  <BookmarkIcon className="w-6 h-6" />
                </div>
                <h2 className="title-font font-medium  text-black">
                  Certification
                </h2>
                <p className="leading-relaxed text-black ">
                  Acquire industry-recognized certifications to boost your
                  career prospects and succeed in global exams.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font bg-gray-50 py-12">
        <div className="container px-5 mx-auto md:pb-18 max-w-7xl">

          <div className="flex flex-col text-center w-full mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Learning with Cutting-Edge Technology
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Explore the Array of Industry-Standard Tools and Technologies that
              Drive Innovation in Our Courses.
            </p>
          </div>
          <div className="flex flex-wrap justify-center md:gap-4 lg:gap-0">
            <div className="lg:w-1/6 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={Figma} />

                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Figma
                  </h2>
                </div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={AI} />

                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Adobe Illustrator
                  </h2>
                </div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={PS} />

                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Adobe Photoshop
                  </h2>
                </div>
              </div>
            </div>

            <div className="lg:w-1/6 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={SK} />

                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Sketch
                  </h2>
                </div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={GIT} />

                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    GitHub
                  </h2>
                </div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={DC} />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Docker
                  </h2>
                </div>
              </div>
            </div>
            <div className="lg:w-1/6 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={AWS} />

                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">AWS</h2>
                </div>
              </div>
            </div>

            <div className="lg:w-1/4 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={JS} />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    JavaScript
                  </h2>
                </div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-3 mr-2">
              <div className="flex items-center border border-purple-200 rounded-full bg-gray-50">
                <img alt="team" className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src={REACT} />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    ReactJS
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
