// CoursesPage.js
import React, { useState } from 'react';
import CourseCard from '../components/CourseCard';
import FilterSection from '../components/FilterSection';
import coursesData from '../data/courses.json';  

const CoursesPage = () => {
  const [filteredCourses, setFilteredCourses] = useState(coursesData);

  const handleFilter = (filter) => {
    const filtered = coursesData.filter(course => {
      return (
        course.title.toLowerCase().includes(filter.toLowerCase()) ||
        course.description.toLowerCase().includes(filter.toLowerCase()) ||
        (course.topics && Array.isArray(course.topics) && course.topics.some(topic => topic.title.toLowerCase().includes(filter.toLowerCase())))
      );
    });

    setFilteredCourses(filtered);
  };

  return (
    <>
    <div className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
      <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
        {/* Hero */}
        <div className="hero-content mx-auto text-center w-[85%] md:w-[92%] xl:w-[75%]">
          <h1 className="mb-4 md:my-6 text-xl py- 48 md:text-2xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
          Empower Your Expertise
          </h1>
          <p className="text-white mb-8"> Discover a world of possibilities in IT education. Our courses are designed to equip you with the latest skills and knowledge in cutting-edge technologies. From programming languages to cloud computing, dive into comprehensive learning experiences curated by industry experts. Elevate your career, explore new opportunities, and stay at the forefront of innovation.</p>
          <div className=" flex justify-center md:px-4 pb-4 py-10 mx-auto mb-10 font-normal text-sm text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
          <FilterSection handleFilter={handleFilter} />
          </div>
        </div>
      </div>
    </div>
    <div className="max-w-7xl mx-auto"> 
      <div className="p-6 px-6 lg:px-10 md:px-8 xl:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredCourses.map(course => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      </div>
    </div></>
  );
};

export default CoursesPage;