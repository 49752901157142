import {
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import Limitless from "../assets/images/limitless-learningac-ademy.webp";
import About from "../assets/images/about.webp";
import CTA from "../assets/images/cta.webp";

function AboutUs() {
  return (
    <main className="design_ui_ux">
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[140px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center pb-10">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Fostering Student Growth Through Innovative Education
                </h1>
                <p className="mb-12 md:ml-10 px-4 mx-auto font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  At Smew Academy, we are dedicated to fostering your journey of
                  growth and excellence in the dynamic fields of design,
                  development, and technology. Our mission is to empower
                  individuals with the knowledge and skills necessary to excel
                  in today's rapidly evolving digital landscape.
                </p>
              </div>
            </div>

            {/* <Svg /> */}
            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-2 md:px-6 pt-1 md:py-2 rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 mr-10">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-normal md:font-medium text-gray-100 hover:text-[#f89539]"
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/about-us"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-normal md:font-medium md:ml-2">
                        About Us
                      </span>
                    </NavLink>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className=" text-black bg-gray-50">
        <div className="max-w-7xl mx-auto  md:py-22 ">
          <div className="grid grid-cols-1 lg:grid-cols-2 py-5 gap-6">
            <div className="w-full flex justify-center items-center px-8">
              <img src={About} className="mx-auto " alt="Bootstrap Themes" />
            </div>
            <div className="text-left mb-3 py-4 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <h1 className="text-[2rem] font-light  mb-3">Who we are</h1>
              <p className="text-justify py-2">
                Smew Academy is a leading online academy dedicated to providing
                high-quality courses in design, development, and IT-related
                fields. With a team of industry experts and seasoned
                professionals, we offer a diverse range of courses tailored to
                meet the demands of today's dynamic job market.
              </p>
              <ul className="pl-0 pt-2">
                <li className="border-l-4 border-gray-600 pl-4 py-2 mb-3 text-base">
                  Dedicated to Learning: Smew Academy is your gateway to
                  comprehensive education in design, development, and IT fields.
                </li>
                <li className="border-l-4 border-[#e2f72d] pl-4 py-2 mb-3 text-base">
                  Crafting Digital Excellence: We specialize in computer
                  education and the art of creating visually appealing designs.
                </li>
                <li className="border-l-4 border-[#ca6262] pl-4 py-2 mb-3 text-base">
                  Your Success, Our Mission: We're committed to supporting you
                  in excelling and reaching your professional aspirations.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600  body-font">
        <div className="container px-6 py-24 mx-auto lg:px-10 md:px-8 xl:px-0">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
              WHY US
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Empowering Your Career Journey
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Bridge theory to practice with immersive projects and globally
              recognized certifications, distinguishing yourself in your career.
              Smew Academy equips you with practical skills and credentials that
              make a lasting impact in the ever-evolving tech and design
              industries.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8">
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60 shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Expert-Led Instruction
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Our courses are taught by industry experts and seasoned
                professionals who bring real-world experience into the
                classroom. Benefit from their wealth of knowledge and insights.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Cutting-Edge Curriculum
              </h2>
              <p className="leading-relaxed text-base mb-4">
                {" "}
                Stay at the forefront of technology with our up-to-date
                curriculum. We continuously adapt to industry trends, ensuring
                you learn the latest tools and techniques.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Hands-On Experience
              </h2>
              <p className="leading-relaxed text-base mb-4">
                {" "}
                Gain practical, hands-on experience through live projects. Apply
                your skills in real-world scenarios, building a portfolio that
                sets you apart in the job market.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Certification Preparation
              </h2>
              <p className="leading-relaxed text-base mb-4">
                We're dedicated to your success. Prepare for and earn globally
                recognized certifications, boosting your credibility and opening
                doors to new career opportunities.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Flexible Learning
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Our courses are designed to accommodate diverse schedules and
                learning styles. Whether you prefer self-paced learning or
                structured classes, we have options for you.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Affordable Education
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Quality education shouldn't come at a high cost. We offer
                budget-friendly courses and flexible payment plans, making
                top-notch training accessible to all.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Community and Networking
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Join a vibrant community of learners, instructors, and industry
                professionals. Expand your network, collaborate on projects, and
                access valuable resources.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Career Support
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Beyond the classroom, we're here to support your career journey.
                Benefit from job placement assistance, resume reviews, and
                interview preparation to help you land your dream job.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Personalized Guidance
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Receive one-on-one guidance and mentorship from our experienced
                instructors. We're committed to your success and are here to
                help you reach your goals.
              </p>
            </div>
            <div className="px-8 py-6 border-l-2 border-gray-200 border-opacity-60  shadow-md">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Innovation and Creativity
              </h2>
              <p className="leading-relaxed text-base mb-4">
                {" "}
                Foster your creativity and innovation in a supportive learning
                environment. Explore new ideas, collaborate on projects, and
                bring your visions to life.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10 md:py-16 lg:py-24 px-6 lg:px-10 md:px-8 xl:px-0">
        <div className="max-w-7xl mx-auto ">
          <div className="flex flex-col text-center w-full ">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
              WHAT WE DO
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Elevating Your Professional Path
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              At Smew Academy, we're committed to transforming your career
              journey by seamlessly integrating theory with hands-on experience
              and offering globally acclaimed certifications that set you apart
              in your chosen field. We provide you with tangible skills and
              qualifications that leave an indelible mark in the ever-evolving
              landscapes of technology and design.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#E9DEFF] rounded-full w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-black stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Teaching Skills
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We teach you important things you need to know. This includes
                how to make things look nice and work well.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#E9DEFF] rounded-full w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-black stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Real-world Practice
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We give you hands-on experience with real projects. This way,
                you get good at it like a pro.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#E9DEFF] rounded-full w-12 h-12">
                  <QuestionMarkCircleIcon className="h-8 w-8 text-black stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Career Help
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We get you ready for a job in technology or design. Our goal is
                to help you find a job and do well in it.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#E9DEFF] rounded-full w-12 h-12">
                  <LightBulbIcon className="h-8 w-8 text-black stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Friendly Support
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We're here to help you at every step. We're like a friend you
                can ask for help. We want you to succeed.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="flex-items-end  container max-w-full flex md:flex-row flex-col items-center  bg-[#492196]">
          <div className="md:w-1/2 lg:w-full md:mb-0">
            <img
              className="object-cover object-center h-96"
              alt="hero"
              src={Limitless}
            />
          </div>
          <div className="md:w-1/2 lg:w-full md:items-center text-white md:text-center items-center text-center">
            <h1 className="title-font text-xl sm:text-4xl md:text-3xl mb-4 font-light text-white">
              Limitless Learning Academy
            </h1>
            <p className="items-center text-center px-3 pb-5 md:px-24 leading-relaxed">
              At our academy, we believe in limitless learning. Here, there are
              no boundaries to your education, as we empower you to excel in
              these dynamic fields. Join us, and together, let's unleash your
              potential in technology and design.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Start Your Journey to IT Excellence
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                  Explore our curated selection of IT courses and stay ahead in
                  the ever-evolving tech industry.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Get Started
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              { <img src={CTA} alt="CTA" className="rounded-xl" /> }
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AboutUs;
