import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/AboutUs";
import Mentors from "./pages/mentors";
import CourseDetails from "./pages/CourseDetails";
import CoursesPage from "./pages/CoursesPage";
import KnowledgeCenter from "./pages/knowledgeCenter";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/scrollTop";

createRoot(document.getElementById("root")).render(
  <Router>
    <Navigation />
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/course-details/:courseSlug" element={<CourseDetails />} />
      <Route path="/courses" element={<CoursesPage />} />
      <Route path="/mentors" element={<Mentors />} />
      <Route path="/knowledge-center" element={<KnowledgeCenter />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <Footer />
  </Router>
);
