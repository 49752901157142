import React, { useState } from "react";
import KnowledgeData from "../data/knowledgeData.json";

const KnowledgeCenterCard = ({ searchQuery }) => {
  const [selectedTab, setSelectedTab] = useState("all");

  const filteredData = KnowledgeData.filter((dataItem) => {
    const matchCategory =
      selectedTab === "all" || dataItem.category === selectedTab;
    const matchSearch =
      !searchQuery ||
      dataItem.question.toLowerCase().includes(searchQuery.toLowerCase());

    return matchCategory && matchSearch;
  });

  const uniqueCategories = Array.from(
    new Set(KnowledgeData.map((dataItem) => dataItem.category))
  );

  return (
    <div>
      <div className="flex-col md:flex-row lg:flex mb-4  ">
        {["all", ...uniqueCategories].map((category) => (
          <button
            key={category}
            onClick={() => setSelectedTab(category)}
            className={`mr-2 px-4 py-2 rounded-lg my-2 md:my-0 ${
              selectedTab === category
                ? "bg-orange-500 text-white"
                : "bg-gray-300 text-gray-700"
            }`}
          >
            {category === "all" ? "All" : category}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-4 py-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredData.map((dataItem) => (
          <div key={dataItem.id} className="p-0">
            <div className="bg-white p-6 rounded-lg shadow-md ">
              <h2 className="text-xl font-semibold line-clamp-2">
                {dataItem.question}
              </h2>
              <p className="mt-4 line-clamp-3">{dataItem.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowledgeCenterCard;
